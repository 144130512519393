<template>
  <div id="dashboard">
    <!-- ===All Dashboard Screens=== -->
    <div
      class="container-fluid ptb-responsive"
      v-if="
        $store.state.courts && $store.state.courts.courtsGroup
      "
    >
      <!-- ROW 1 -->
      <div class="col-md-12">
        <div class="row">
          <!-- INDOOR SHOW COURT -->
          <div class="col-md-5 pr-3 pl-0 pt-0 pb-0" id="show-court">
            <div class="col-md-12 pl-2 pr-2 pt-4 pb-2">
              <h3 class="m-0 title-heading">INDOOR SHOW COURT</h3>
            </div>
            <div class="col-md-12 background-row rounded">
              <div class="row">
                <div
                  v-for="(isc, index) in $store.state.courts.courtsGroup
                    .indoor_show_group"
                  :key="index"
                >
                  <div class="background-box rounded border-grey">
                    <div class="current-state-icon">
                      <div class="schedule-icon mr-2" v-if="isc.scheduled == 1">
                        <img
                          src="@/assets/icon_calendar.svg"
                          class="size-icon"
                        />
                      </div>
                      <div class="wifi-icon" v-if="isc.online == 0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <img
                              src="@/assets/icon_wifi_disconnect.svg"
                              class="size-icon"
                              v-bind="attrs"
                              v-on="on"
                            />
                          </template>
                          <span>SC is not connected to server</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div class="box">
                      <div class="row width-mode">
                        <div class="d-flex class-remote">
                          <div
                            class="col-md-12 p-0 d-flex justify-content-center align-items-center"
                          >
                            <input
                              class="btn text-white class-name background-black"
                              :class="`${
                                isc.online == 0 ? 'not-connection-button' : ''
                              } ${isc.expected_on == 0 ? 'off-state' : ''}
                                ${isc.online == 1 && isc.on != isc.expected_on ? 'flashing-on-off' : ''}
                                  `"
                              type="button"
                              :disabled="isc.expected_on != isc.on"
                              v-model="isc.name"
                              @click="
                                CheckOnOffState('indoor_show_group', index)
                              "
                            />
                          </div>
                        </div>
                        <div class="class-mode">
                          <div
                            class="border"
                            :class="`${isc.online == 0 ? 'mode-grey-out' : ''}`"
                            v-for="(item_mode, subIndex) in isc.modes"
                            :key="item_mode"
                          >
                            <input
                              class="btn btn-block"
                              :disabled="isc.expected_mode != isc.mode"
                              :class="`${
                                isc.expected_mode != isc.mode &&
                                isc.expected_mode == isc.modes[subIndex]
                                  ? 'flashing-mode'
                                  : isc.expected_mode == isc.mode &&
                                    isc.mode == isc.modes[subIndex] &&
                                    isc.on == isc.expected_on &&
                                    isc.on == 1
                                  ? 'active-mode'
                                  : 'default-mode'
                              } ${
                                isc.online == 0 ? 'not-connection-button bg-grey-out' : ''
                              } ${
                                !isc.expected_on || !isc.on ? 'not-click' : ''
                              }
                                  `"
                              type="button"
                              v-model="isc.modes[subIndex]"
                              @click="
                                CheckModeState(
                                  'indoor_show_group',
                                  index,
                                  subIndex
                                )
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row width-state m-0">
                        <div
                          class="col-md-6 border"
                          :class="`${
                            isc.power_consumption.toString().length > 4
                              ? 'plr-0'
                              : ''
                          }`"
                        >
                          <div class="wp-led" v-html="getRoundDecimal(isc)"></div>
                        </div>
                        <div class="col-md-6 border">
                          <v-tooltip v-if="isc.led === 'OK' && isc.online" top>
                            <template v-slot:activator="{ on, attrs }">
                              <div class="wp-led" v-html="getStateCourt(isc)" v-bind="attrs" v-on="on"></div>
                            </template>
                            <span>No lighting failure detected</span>
                          </v-tooltip>
                          <div v-else class="wp-led" v-html="getStateCourt(isc)"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Indoor Show Group virtual box on Ipad -->
                <div class="background-box rounded border-grey ipad-virtual-box virtual-box-none-731">
                  <div class="box">
                    <div class="row width-mode">
                      <div class="d-flex class-remote">
                        <div
                          class="col-md-12 p-0 d-flex justify-content-center align-items-center"
                        >
                          <input
                            class="btn text-white class-name background-black"
                            type="button"
                          />
                        </div>
                      </div>
                      <div class="class-mode mb-3">
                        <div
                          class="border"
                        >
                          <input
                            class="btn btn-block m-0"
                            type="button"
                          />
                          <input
                            class="btn btn-block m-0"
                            type="button"
                          />
                          <input
                            class="btn btn-block m-0"
                            type="button"
                          />
                          <input
                            class="btn btn-block m-0"
                            type="button"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row width-state m-0">
                      <div
                        class="col-md-6 border"
                      >
                        <div class="wp-led"></div>
                      </div>
                      <div class="col-md-6 border">
                        <div class="wp-led"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Indoor Show Group virtual box on Ipad -->
              </div>
            </div>
          </div>
          <!-- END INDOOR SHOW COURT -->

          <!-- INDOOR PRACTICE COURT -->
          <div class="col-md-7 pl-3 pr-0 pt-0 pb-0" id="practice-court">
            <div class="col-md-12 pl-2 pr-2 pt-4 pb-2">
              <h3 class="m-0 title-heading">INDOOR PRACTICE COURT</h3>
            </div>
            <div class="col-md-12 background-row rounded">
              <div class="row">
                <div
                  v-for="(ipc, index) in $store.state.courts.courtsGroup
                    .indoor_practice_group"
                  :key="index"
                >
                  <div class="background-box rounded border-grey">
                    <div class="current-state-icon">
                      <div class="schedule-icon mr-2" v-if="ipc.scheduled == 1">
                        <img
                          src="@/assets/icon_calendar.svg"
                          class="size-icon"
                        />
                      </div>
                      <div class="wifi-icon" v-if="ipc.online == 0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <img
                              src="@/assets/icon_wifi_disconnect.svg"
                              class="size-icon"
                              v-bind="attrs"
                              v-on="on"
                            />
                          </template>
                          <span>SC is not connected to server</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div class="box">
                      <div class="row width-mode">
                        <div class="d-flex class-remote">
                          <div
                            class="col-md-12 p-0 d-flex justify-content-center align-items-center"
                          >
                            <input
                              class="btn text-white class-name background-black"
                              :class="`${
                                ipc.online == 0 ? 'not-connection-button' : ''
                              } ${ipc.expected_on == 0 ? 'off-state' : ''}
                                ${ipc.online == 1 && ipc.on != ipc.expected_on ? 'flashing-on-off' : ''}
                                  `"
                              type="button"
                              :disabled="ipc.expected_on != ipc.on"
                              v-model="ipc.name"
                              @click="
                                CheckOnOffState('indoor_practice_group', index)
                              "
                            />
                          </div>
                        </div>
                        <div class="class-mode">
                          <div
                            class="col-md-12 p-0 border"
                            :class="`${ipc.online == 0 ? 'mode-grey-out' : ''}`"
                            v-for="(item_mode, subIndex) in ipc.modes"
                            :key="item_mode"
                          >
                            <input
                              class="btn btn-block"
                              :disabled="ipc.expected_mode != ipc.mode"
                              :class="`${
                                ipc.expected_mode != ipc.mode &&
                                ipc.expected_mode == ipc.modes[subIndex]
                                  ? 'flashing-mode'
                                  : ipc.expected_mode == ipc.mode &&
                                    ipc.mode == ipc.modes[subIndex] &&
                                    ipc.expected_on == ipc.on &&
                                    ipc.on == 1
                                  ? 'active-mode'
                                  : 'default-mode'
                              } ${
                                ipc.online == 0 ? 'not-connection-button bg-grey-out' : ''
                              } ${
                                !ipc.expected_on || !ipc.on ? 'not-click' : ''
                              }
                                  `"
                              type="button"
                              v-model="ipc.modes[subIndex]"
                              @click="
                                CheckModeState(
                                  'indoor_practice_group',
                                  index,
                                  subIndex
                                )
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row width-state m-0">
                        <div
                          class="col-md-6 border"
                          :class="`${
                            ipc.power_consumption.toString().length > 4
                              ? 'plr-0'
                              : ''
                          }`"
                        >
                          <div class="wp-led" v-html="getRoundDecimal(ipc)"></div>
                        </div>
                        <div class="col-md-6 border">
                          <v-tooltip v-if="ipc.led === 'OK' && ipc.online" top>
                            <template v-slot:activator="{ on, attrs }">
                              <div class="wp-led" v-html="getStateCourt(ipc)" v-bind="attrs" v-on="on"></div>
                            </template>
                            <span>No lighting failure detected</span>
                          </v-tooltip>
                          <div v-else class="wp-led" v-html="getStateCourt(ipc)"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END INDOOR PRACTICE COURT -->
        </div>
      </div>
      <!-- END ROW 1 -->

      <!-- ROW 2 -->
      <div class="col-md-12">
        <div class="row">
          <!-- OUTDOOR TENNIS COURT -->
          <div class="col-md-7 pr-1 pl-0 pt-0 pb-0" id="tennis-court">
            <div class="col-md-12 pl-2 pr-2 pt-5 pb-2">
              <h3 class="m-0 title-heading">OUTDOOR TENNIS COURT</h3>
            </div>
            <div class="col-md-12 background-row rounded">
              <!-- ROW 1 OF OUTDOOR TENNIS COURT -->
              <div class="row">
                <div
                  class="mt-tennis-court"
                  v-for="(otc, index) in $store.state.courts.courtsGroup
                    .outdoor_tennis_group"
                  :key="index"
                >
                  <div class="background-box rounded border-grey">
                    <div class="current-state-icon">
                      <div class="schedule-icon mr-2" v-if="otc.scheduled == 1">
                        <img
                          src="@/assets/icon_calendar.svg"
                          class="size-icon"
                        />
                      </div>
                      <div class="wifi-icon" v-if="otc.online == 0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <img
                              src="@/assets/icon_wifi_disconnect.svg"
                              class="size-icon"
                              v-bind="attrs"
                              v-on="on"
                            />
                          </template>
                          <span>SC is not connected to server</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div class="box">
                      <div class="row width-mode">
                        <div class="d-flex class-remote">
                          <div
                            class="col-md-12 p-0 d-flex justify-content-center align-items-center"
                          >
                            <input
                              class="btn text-white class-name background-black"
                              :class="`${
                                otc.online == 0 ? 'not-connection-button' : ''
                              } ${otc.expected_on == 0 ? 'off-state' : ''}
                                ${otc.online == 1 && otc.on != otc.expected_on ? 'flashing-on-off' : ''}
                                  `"
                              type="button"
                              :disabled="otc.expected_on != otc.on"
                              v-model="otc.name"
                              @click="
                                CheckOnOffState('outdoor_tennis_group', index)
                              "
                            />
                          </div>
                        </div>
                        <div class="class-mode">
                          <div
                            class="col-md-12 p-0 border"
                            :class="`${otc.online == 0 ? 'mode-grey-out' : ''}`"
                            v-for="(item_mode, subIndex) in otc.modes"
                            :key="item_mode"
                          >
                            <input
                              class="btn btn-block"
                              :disabled="otc.expected_mode != otc.mode"
                              :class="`${
                                otc.expected_mode != otc.mode &&
                                otc.expected_mode == otc.modes[subIndex]
                                  ? 'flashing-mode'
                                  : otc.expected_mode == otc.mode &&
                                    otc.mode == otc.modes[subIndex] &&
                                    otc.expected_on == otc.on &&
                                    otc.on == 1
                                  ? 'active-mode'
                                  : 'default-mode'
                              } ${
                                otc.online == 0 ? 'not-connection-button bg-grey-out' : ''
                              } ${
                                !otc.expected_on || !otc.on ? 'not-click' : ''
                              }
                                  `"
                              type="button"
                              v-model="otc.modes[subIndex]"
                              @click="
                                CheckModeState(
                                  'outdoor_tennis_group',
                                  index,
                                  subIndex
                                )
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row width-state m-0">
                        <div
                          class="col-md-6 border"
                          :class="`${
                            otc.power_consumption.toString().length > 4
                              ? 'plr-0'
                              : ''
                          }`"
                        >
                          <div class="wp-led" v-html="getRoundDecimal(otc)"></div>
                        </div>
                        <div class="col-md-6 border">
                          <v-tooltip v-if="otc.led === 'OK' && otc.online" top>
                            <template v-slot:activator="{ on, attrs }">
                              <div class="wp-led" v-html="getStateCourt(otc)" v-bind="attrs" v-on="on"></div>
                            </template>
                            <span>No lighting failure detected</span>
                          </v-tooltip>
                          <div v-else class="wp-led" v-html="getStateCourt(otc)"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END ROW 1 OF OUTDOOR TENNIS COURT -->
            </div>
          </div>
          <!-- END OUTDOOR TENNIS COURT -->

          <!-- JUNIOR COURT -->
          <div class="col-md-2 pl-2 pr-2 pt-0 pb-0" id="junior-court">
            <div class="col-md-12 pl-2 pr-2 pt-5 pb-2">
              <h3 class="m-0 title-heading">JUNIOR COURT</h3>
            </div>
            <div class="col-md-12 background-row rounded">
              <!-- ROW 1 OF JUNIOR COURT -->
              <div class="row">
                <div
                  class="mt-junior-court"
                  v-for="(jc, index) in $store.state.courts.courtsGroup.junior"
                  :key="index"
                >
                  <div class="background-box rounded border-grey">
                    <div class="current-state-icon">
                      <div class="schedule-icon mr-2" v-if="jc.scheduled == 1">
                        <img
                          src="@/assets/icon_calendar.svg"
                          class="size-icon"
                        />
                      </div>
                      <div class="wifi-icon" v-if="jc.online == 0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <img
                              src="@/assets/icon_wifi_disconnect.svg"
                              class="size-icon"
                              v-bind="attrs"
                              v-on="on"
                            />
                          </template>
                          <span>SC is not connected to server</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div class="box">
                      <div class="row width-mode">
                        <div class="d-flex class-remote">
                          <div
                            class="col-md-12 p-0 d-flex justify-content-center align-items-center"
                          >
                            <input
                              class="btn text-white class-name background-black"
                              :class="`${
                                jc.online == 0 ? 'not-connection-button' : ''
                              } ${jc.expected_on == 0 ? 'off-state' : ''}
                                ${jc.online == 1 && jc.on != jc.expected_on ? 'flashing-on-off' : ''}
                                  `"
                              type="button"
                              :disabled="jc.expected_on != jc.on"
                              v-model="jc.name"
                              @click="CheckOnOffState('junior', index)"
                            />
                          </div>
                        </div>
                        <div class="class-mode">
                          <div
                            class="col-md-12 p-0 border"
                            :class="`${jc.online == 0 ? 'mode-grey-out' : ''}`"
                            v-for="(item_mode, subIndex) in jc.modes"
                            :key="item_mode"
                          >
                            <input
                              class="btn btn-block"
                              :disabled="jc.expected_mode != jc.mode"
                              :class="`${
                                jc.expected_mode != jc.mode &&
                                jc.expected_mode == jc.modes[subIndex]
                                  ? 'flashing-mode'
                                  : jc.expected_mode == jc.mode &&
                                    jc.mode == jc.modes[subIndex] &&
                                    jc.expected_on == jc.on &&
                                    jc.on == 1
                                  ? 'active-mode'
                                  : 'default-mode'
                              } ${
                                jc.online == 0 ? 'not-connection-button bg-grey-out' : ''
                              } ${!jc.expected_on || !jc.on ? 'not-click' : ''}
                                  `"
                              type="button"
                              v-model="jc.modes[subIndex]"
                              @click="CheckModeState('junior', index, subIndex)"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row width-state m-0">
                        <div
                          class="col-md-6 border"
                          :class="`${
                            jc.power_consumption.toString().length > 4
                              ? 'plr-0'
                              : ''
                          }`"
                        >
                          <div class="wp-led" v-html="getRoundDecimal(jc)"></div>
                        </div>
                        <div class="col-md-6 border">
                          <v-tooltip v-if="jc.led === 'OK' && jc.online" top>
                            <template v-slot:activator="{ on, attrs }">
                              <div class="wp-led" v-html="getStateCourt(jc)" v-bind="attrs" v-on="on"></div>
                            </template>
                            <span>No lighting failure detected</span>
                          </v-tooltip>
                          <div v-else class="wp-led" v-html="getStateCourt(jc)"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Junior Group virtual box on Ipad -->
                <div class="background-box rounded border-grey ipad-virtual-box mobile-display-none">
                  <div class="box">
                    <div class="row width-mode">
                      <div class="d-flex class-remote">
                        <div
                          class="col-md-12 p-0 d-flex justify-content-center align-items-center"
                        >
                          <input
                            class="btn text-white class-name background-black"
                            type="button"
                          />
                        </div>
                      </div>
                      <div class="class-mode">
                        <div
                          class="col-md-12 p-0 border"
                        >
                          <input
                            class="btn btn-block m-0"
                            type="button"
                          />
                          <input
                            class="btn btn-block m-0"
                            type="button"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row width-state m-0">
                      <div
                        class="col-md-6 border"
                      >
                        <div class="wp-led"></div>
                      </div>
                      <div class="col-md-6 border">
                        <div class="wp-led"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="background-box rounded border-grey ipad-virtual-box mobile-display-none mobile-display-none-2">
                  <div class="box">
                    <div class="row width-mode">
                      <div class="d-flex class-remote">
                        <div
                          class="col-md-12 p-0 d-flex justify-content-center align-items-center"
                        >
                          <input
                            class="btn text-white class-name background-black"
                            type="button"
                          />
                        </div>
                      </div>
                      <div class="class-mode">
                        <div
                          class="col-md-12 p-0 border"
                        >
                          <input
                            class="btn btn-block m-0"
                            type="button"
                          />
                          <input
                            class="btn btn-block m-0"
                            type="button"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row width-state m-0">
                      <div
                        class="col-md-6 border"
                      >
                        <div class="wp-led"></div>
                      </div>
                      <div class="col-md-6 border">
                        <div class="wp-led"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Junior Group virtual box on Ipad -->
              </div>
              <!-- END ROW 1 OF JUNIOR COURT -->
            </div>
          </div>
          <!-- END JUNIOR COURT -->

          <!-- ROOFTOP COURT -->
          <div class="col-md-3 pl-1 pr-0 pt-0 pb-0" id="rooftop-court">
            <div class="col-md-12 pl-2 pr-2 pt-5 pb-2">
              <h3 class="m-0 title-heading">ROOFTOP COURT</h3>
            </div>
            <div class="col-md-12 background-row rounded">
              <!-- ROW 1 OF ROOFTOP COURT -->
              <div class="row">
                <div
                  class="mt-rooftop-court"
                  v-for="(rc, index) in $store.state.courts.courtsGroup
                    .rooftop_group"
                  :key="index"
                >
                  <div class="background-box rounded border-grey">
                    <div class="current-state-icon">
                      <div class="schedule-icon mr-2" v-if="rc.scheduled == 1">
                        <img
                          src="@/assets/icon_calendar.svg"
                          class="size-icon"
                        />
                      </div>
                      <div class="wifi-icon" v-if="rc.online == 0">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <img
                              src="@/assets/icon_wifi_disconnect.svg"
                              class="size-icon"
                              v-bind="attrs"
                              v-on="on"
                            />
                          </template>
                          <span>SC is not connected to server</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div class="box">
                      <div class="row width-mode">
                        <div class="d-flex class-remote">
                          <div
                            class="col-md-12 p-0 d-flex justify-content-center align-items-center"
                          >
                            <input
                              class="btn text-white class-name background-black"
                              :class="`${
                                rc.online == 0 ? 'not-connection-button' : ''
                              } ${rc.expected_on == 0 ? 'off-state' : ''}
                                ${rc.online == 1 && rc.on != rc.expected_on ? 'flashing-on-off' : ''}
                                  `"
                              type="button"
                              :disabled="rc.expected_on != rc.on"
                              v-model="rc.name"
                              @click="CheckOnOffState('rooftop_group', index)"
                            />
                          </div>
                        </div>
                        <div class="class-mode">
                          <div
                            class="col-md-12 p-0 border"
                            :class="`${rc.online == 0 ? 'mode-grey-out' : ''}`"
                            v-for="(item_mode, subIndex) in rc.modes"
                            :key="item_mode"
                          >
                            <input
                              class="btn btn-block"
                              :disabled="rc.expected_mode != rc.mode"
                              :class="`${
                                rc.expected_mode != rc.mode &&
                                rc.expected_mode == rc.modes[subIndex]
                                  ? 'flashing-mode'
                                  : rc.expected_mode == rc.mode &&
                                    rc.mode == rc.modes[subIndex] &&
                                    rc.expected_on == rc.on &&
                                    rc.on == 1
                                  ? 'active-mode'
                                  : 'default-mode'
                              } ${
                                rc.online == 0 ? 'not-connection-button bg-grey-out' : ''
                              } ${!rc.expected_on || !rc.on ? 'not-click' : ''}
                                  `"
                              type="button"
                              v-model="rc.modes[subIndex]"
                              @click="
                                CheckModeState('rooftop_group', index, subIndex)
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row width-state m-0">
                        <div
                          class="col-md-6 border p-rooftop-court"
                          :class="`${
                            rc.power_consumption.toString().length > 4
                              ? 'plr-0'
                              : ''
                          }`"
                        >
                          <div class="wp-led" v-html="getRoundDecimal(rc)"></div>
                        </div>
                        <div class="col-md-6 border p-rooftop-court">
                          <v-tooltip v-if="rc.led === 'OK' && rc.online" top>
                            <template v-slot:activator="{ on, attrs }">
                              <div class="wp-led" v-html="getStateCourt(rc)" v-bind="attrs" v-on="on"></div>
                            </template>
                            <span>No lighting failure detected</span>
                          </v-tooltip>
                          <div v-else class="wp-led" v-html="getStateCourt(rc)"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END ROW 1 OF ROOFTOP COURT -->
            </div>
          </div>
          <!-- END ROOFTOP COURT -->
        </div>
      </div>
      <!-- END ROW 2 -->
    </div>
    <!-- ===End All Dashboard Screens=== -->

    <!-- Message popup -->
    <v-dialog
      v-model="messageDialog"
      max-width="500"
      persistent
      content-class="profile-confirm-dialog"
    >
      <v-card id="profile-confirm" class="dialog_content">
        <v-card-title style="background-color: #111111;"></v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" class="message_content">
              <div v-html="apiMessage"></div>
            </v-col>
            <v-col cols="12" class="close_btn_content">
              <button
                class="btn btn-primary btn_black"
                :ripple="false"
                @click="closeMessage"
              >
                RETURN TO DASHBOARD
              </button>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
    <!-- End Message popup -->
  </div>
</template>

<style lang="scss" src="../assets/css/dashboard.scss"></style>
<script>
import { courtService } from "../services";
import iconLedOk from '@/assets/icon_led_ok.svg';

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      interval: null,
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      groupModes: {},
      firstLoad: true,
      iconLedOk: iconLedOk,
      timeoutHandleCurrentCourtState: {},
      timeoutHandleCurrentCourtMode: {},
      timeoutHandleFilterCourtState: [],
      timeoutHandleFilterCourtMode: [],
    };
  },
  async created() {
    await this.getListModes();
    await this.getListCourts();
  },
  mounted() {
    const vm = this;
    this.interval = setInterval(() => {
      // vm.testCourts();
      vm.getListCourts();
    }, 1000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    getRoundDecimal(court) {
      if (court.online === 0) {
        return `<span class='text-13'>—</span>`;
      } else {
        let power = parseFloat(Math.round(court.power_consumption * 1000) / 1000);
        return `<span class='text-13'>${power} kW</span>`;
      }
    },
    getStateCourt(court) {
      if (court.online === 0) {
        return `<span class='text-13'>—</span>`;
      } else {
        if (court.led == "OK") {
          return `<img src="${iconLedOk}" class="size-icon"/>`;
        } else {
          return `<span class='text-18 text-red'>${court.led}</span>`;
        }
      }
    },
    async CheckOnOffState(nameCourtsGroup, index) {
      if (
        this.$store.state.courts.courtsGroup[nameCourtsGroup][index]
          .scheduled == 1
      ) {
        this.apiMessage =
          "The light group is currently on a schedule. Please wait until the schedule ends before trying to change the light settings.";
        this.apiTitle = "Warning";
        this.messageDialog = true;
      } else {
        let currentData =
          this.$store.state.courts.courtsGroup[nameCourtsGroup][index];
        currentData.expected_on = currentData.on == 1 ? 0 : 1;
        this.$store.dispatch("courts/updatedCourt", {
          nameCourtsGroup: nameCourtsGroup,
          currentData: currentData,
        });
        await this.changeCourtOnOff(currentData.id, {
          on: currentData.expected_on,
        });
        if(this.timeoutHandleCurrentCourtState[currentData.id] !== null){
          clearTimeout(this.timeoutHandleCurrentCourtState[currentData.id]);
        }
        this.timeoutHandleCurrentCourtState[currentData.id] = setTimeout(() => {
          this.handelUpdateCourtOn(currentData);
        }, this.TIMEOUT_UPDATE_COURT);
      }
    },
    changeCourtOnOff(id, data) {
      courtService
        .changeCourtOnOff(id, data)
        .then((res) => {
          if (res.status !== 200 || (res.data && !res.data.success)) throw res;
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            this.apiMessage = err.response.data.message;
            this.apiTitle = "Warning";
            this.messageDialog = true;
          }
        });
    },
    async CheckModeState(nameCourtsGroup, index, subIndex) {
      if (
        this.$store.state.courts.courtsGroup[nameCourtsGroup][index]
          .scheduled == 1
      ) {
        this.apiMessage =
          "The light group is currently on a schedule. Please wait until the schedule ends before trying to change the light settings.";
        this.apiTitle = "Warning";
        this.messageDialog = true;
      } else {
        let currentData =
          this.$store.state.courts.courtsGroup[nameCourtsGroup][index];
        currentData.expected_mode = currentData.modes[subIndex];
        this.$store.dispatch("courts/updatedCourt", {
          nameCourtsGroup: nameCourtsGroup,
          currentData: currentData,
        });
        await this.changeCourtMode(currentData.id, {
          mode: currentData.expected_mode,
        });
        if(this.timeoutHandleCurrentCourtMode[currentData.id] !== null){
          clearTimeout(this.timeoutHandleCurrentCourtMode[currentData.id]);
        }
        this.timeoutHandleCurrentCourtMode[currentData.id] = setTimeout(() => {
          this.handelUpdateCourtMode(currentData);
        }, this.TIMEOUT_UPDATE_COURT);
      }
    },
    changeCourtMode(id, data) {
      courtService
        .changeCourtMode(id, data)
        .then((res) => {
          if (res.status !== 200 || (res.data && !res.data.success)) throw res;
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            this.apiMessage = err.response.data.message;
            this.apiTitle = "Warning";
            this.messageDialog = true;
          }
        });
    },
    async getListModes() {
      if (
        !this.$store.state.courts.modes ||
        (this.$store.state.courts.modes &&
          this.$store.state.courts.modes.length <= 0)
      ) {
        await this.$store.dispatch("courts/getModes");
      }
      this.groupModes = await this.handelGroupModes(
        this.$store.state.courts.modes
      );
    },
    setTimeoutFilterCourtState(filterCourtsState) {
      this.clearTimeoutFilterCourtState(filterCourtsState.map(item => item.id));
      filterCourtsState.forEach(filter => {
        const timeoutId = setTimeout(() => this.handelUpdateCourtOn(filter), this.TIMEOUT_UPDATE_COURT);
        this.timeoutHandleFilterCourtState.push({ id: filter.id, timeoutId });
      });
    },
    clearTimeoutFilterCourtState(courtStateIds) {
      courtStateIds.forEach(courtStateId => {
        const index = this.timeoutHandleFilterCourtState.findIndex(item => item.id === courtStateId);
        if (index !== -1) {
          clearTimeout(this.timeoutHandleFilterCourtState[index].timeoutId);
          this.timeoutHandleFilterCourtState.splice(index, 1);
        }
      });
    },
    setTimeoutFilterCourtMode(filterCourtsMode) {
      this.clearTimeoutFilterCourtMode(filterCourtsMode.map(item => item.id));
      filterCourtsMode.forEach(filter => {
        const timeoutId = setTimeout(() => this.handelUpdateCourtMode(filter), this.TIMEOUT_UPDATE_COURT);
        this.timeoutHandleFilterCourtMode.push({ id: filter.id, timeoutId });
      });
    },
    clearTimeoutFilterCourtMode(courtModeIds) {
      courtModeIds.forEach(courtModeId => {
        const index = this.timeoutHandleFilterCourtMode.findIndex(item => item.id === courtModeId);
        if (index !== -1) {
          clearTimeout(this.timeoutHandleFilterCourtMode[index].timeoutId);
          this.timeoutHandleFilterCourtMode.splice(index, 1);
        }
      });
    },
    async getListCourts() {
      await this.$store.dispatch("courts/getCourts", this.firstLoad);
      let dataGroup = await this.handelGroupCourts(
        this.groupModes,
        this.$store.state.courts.courts
      );
      await this.$store.dispatch("courts/setCourtsGroup", dataGroup);
      if (this.firstLoad) {
        this.firstLoad = false;
        const filterCourtsState = this.$store.state.courts.courts.filter(
          function (court) {
          return court.expected_on != court.on;
        });
        this.setTimeoutFilterCourtState(filterCourtsState);

        const filterCourtsMode = this.$store.state.courts.courts.filter(
          function (court) {
            return court.expected_mode != court.mode;
          }
        );
        this.setTimeoutFilterCourtMode(filterCourtsMode);
      }
    },
    async testCourts() {
      await courtService.testCourts().then(() => {});
    },
    closeMessage() {
      this.messageDialog = false;
    },
    async handelUpdateCourtOn(courtUpdate) {
      await this.getListCourts();
      const checkData = this.$store.state.courts.courts.filter((item) => {
        return item.id == courtUpdate.id;
      });
      let currentData = checkData.length > 0 ? checkData[0] : null;
      if (currentData && currentData.expected_on != currentData.on) {
        currentData.expected_on = currentData.on;
        this.$store.dispatch("courts/updatedCourt", {
          nameCourtsGroup: this.GROUP_NAME[currentData.group],
          currentData: currentData,
        });
        await this.changeCourtOnOff(currentData.id, { on: currentData.on });
        this.$forceUpdate();
        this.popupConnectionFailed();
      }
    },
    async handelUpdateCourtMode(courtUpdate) {
      await this.getListCourts();
      const checkData = this.$store.state.courts.courts.filter((item) => {
        return item.id == courtUpdate.id;
      });
      let currentData = checkData.length > 0 ? checkData[0] : null;
      if (currentData && currentData.expected_mode != currentData.mode) {
        currentData.expected_mode = currentData.mode;
        this.$store.dispatch("courts/updatedCourt", {
          nameCourtsGroup: this.GROUP_NAME[currentData.group],
          currentData: currentData,
        });
        await this.changeCourtMode(currentData.id, { mode: currentData.mode });
        this.$forceUpdate();
        this.popupConnectionFailed();
      }
    },
    popupConnectionFailed(){
      this.apiMessage = "Failed to send the command. Please check system connection and try again.";
      this.messageDialog = true;
    }
  },
};
</script>
